'use client'

import { Card, Divider, Dialog, Spacer, Text, Icon, List, Cell } from '@vinted/web-ui'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import InfoBanner from 'components/InfoBanner'
import { Screen } from 'constants/tracking/screens'
import useBreakpoint from 'hooks/useBreakpoint'
import useTranslate from 'hooks/useTranslate'
import { getIsWebView } from 'state/session/selectors'

import { getTermsAndConditions, getTermsAndConditionsContent } from 'data/api'
import {
  transformTermsAndConditionsContentResponse,
  transformTermsAndConditionsResponse,
} from 'data/api/transformers/response'
import useFetch from 'hooks/useFetch'

import { ModalInformationDto, TermsAndConditionsActionsDto } from 'types/dtos'

import TermsActions from './TermsActions'

const TermsAndConditions = () => {
  const breakpoints = useBreakpoint()
  const isWebView = useSelector(getIsWebView)
  const translate = useTranslate('terms_and_conditions')
  const [showInformationModal, setShowInformationModal] = useState(false)
  const [modalInformation, setModalInformation] = useState<ModalInformationDto>()

  const { fetch: fetchTermsAndConditions, transformedData: termsAndConditions } = useFetch(
    getTermsAndConditions,
    transformTermsAndConditionsResponse,
  )

  const { fetch: fetchTermsAndConditionsContent, transformedData: termsAndConditionsContent } =
    useFetch(getTermsAndConditionsContent, transformTermsAndConditionsContentResponse)

  useEffect(() => {
    const termsAndConditionsApiTrigger = async () => {
      await Promise.all([fetchTermsAndConditionsContent(), fetchTermsAndConditions()])
    }
    termsAndConditionsApiTrigger()
  }, [fetchTermsAndConditions, fetchTermsAndConditionsContent])

  const hasActions = !termsAndConditions?.canAgree || isWebView

  const renderActions = () => {
    if (hasActions) return null

    return <TermsActions canSkip additionalActions={termsAndConditions?.additionalActions} />
  }

  const renderBottomActions = () => {
    if (hasActions) return null

    return (
      <>
        <Divider />
        <Spacer size={Spacer.Size.Large} />
        {renderActions()}
      </>
    )
  }

  const renderUpdatesContent = () => {
    return (
      <>
        <Text text={termsAndConditionsContent?.updates?.title} type={Text.Type.Heading} as="h2" />
        <Spacer size={breakpoints.desktops ? Spacer.Size.XLarge : Spacer.Size.Medium} />
        <Text text={termsAndConditionsContent?.updates?.text} theme="muted" html />
        <>
          <Spacer size={Spacer.Size.XLarge} />
          {renderActions()}
        </>
      </>
    )
  }

  const renderUpdates = () => {
    if (!termsAndConditionsContent?.updates?.title || !termsAndConditionsContent?.updates?.text)
      return null

    if (breakpoints.desktops) {
      return (
        <>
          <Spacer size={Spacer.Size.X2Large} />
          <Card styling={Card.Styling.Elevated}>
            <div className="u-ui-padding-x2-large">{renderUpdatesContent()}</div>
          </Card>
          <Spacer size={Spacer.Size.X4Large} />
        </>
      )
    }

    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        {renderUpdatesContent()}
        <Spacer size={Spacer.Size.X4Large} />
      </>
    )
  }

  const handleInformationModalShow = information => {
    setShowInformationModal(true)
    setModalInformation(information)
  }

  const handleInformationModalHide = () => {
    setShowInformationModal(false)
  }

  const renderInformationModal = () => {
    return (
      <Dialog
        show={showInformationModal}
        defaultCallback={handleInformationModalHide}
        title={modalInformation?.title}
        body={modalInformation?.text}
        actions={[
          {
            text: translate('modal.actions.close'),
            callback: handleInformationModalHide,
            style: Dialog.ActionStyling.Filled,
          },
        ]}
      />
    )
  }

  const renderAdditionalActions = () => {
    if (termsAndConditionsContent && !termsAndConditionsContent?.additionalActions) return null

    return (
      <div className="u-ui-margin-bottom-x2-large">
        <div className="u-ui-padding-vertical-medium">
          <Text text={termsAndConditionsContent?.additionalActions?.text} html />
        </div>
        <List size={List.Size.Tight}>
          {termsAndConditionsContent?.additionalActions?.actions.map(
            ({ title, url, information }: TermsAndConditionsActionsDto, index: number) => (
              <List.Item key={index}>
                {information ? (
                  <Cell
                    styling={Cell.Styling.Tight}
                    prefix={
                      <a href={url}>
                        <Text underline text={title} type={Text.Type.Title} theme="primary" />
                      </a>
                    }
                    body={
                      <button
                        type="button"
                        style={{ height: '16px' }}
                        className="u-block"
                        onClick={() => handleInformationModalShow(information)}
                        // TODO: Add aria-label
                      >
                        <Icon name={InfoCircle16} color={Icon.Color.Primary} />
                      </button>
                    }
                  />
                ) : (
                  <Cell
                    styling={Cell.Styling.Tight}
                    body={
                      <a href={url}>
                        <Text underline text={title} type={Text.Type.Title} theme="primary" />
                      </a>
                    }
                  />
                )}
                <Spacer />
              </List.Item>
            ),
          )}
        </List>
      </div>
    )
  }

  return (
    <div className="u-padding-none@portables grid8 push2">
      <InfoBanner screen={Screen.TermsAndConditions} />
      {renderUpdates()}
      {renderAdditionalActions()}
      <Text text={termsAndConditionsContent?.title} type={Text.Type.Heading} />
      <Spacer size={Spacer.Size.XLarge} />
      <div className="user-created-content">
        <Text text={termsAndConditionsContent?.text} theme="muted" width={Text.Width.Parent} html />
      </div>
      <Spacer size={Spacer.Size.X2Large} />
      {renderAdditionalActions()}
      {renderBottomActions()}
      {renderInformationModal()}
    </div>
  )
}

export default TermsAndConditions
